import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"

import CirclePlayText from "src/images/home/circle_play_text.inline.svg"
import Playbtn from "src/images/home/playbtn.inline.svg"
import { Video } from "../common"
import classNames from "classnames"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { homeThemeState, modalState } from "src/atoms"
import { graphql, useStaticQuery } from "gatsby"

const PlayShowReel = () => {
  const { video } = useStaticQuery(graphql`
    {
      video: contentfulAsset(contentful_id: { eq: "4ckAPzbMr8ZdoR45CTMsCV" }) {
        ...Asset
      }
    }
  `)
  const theme = useRecoilValue(homeThemeState)
  const showModal = useSetRecoilState(modalState)

  const handleClick = () => showModal(true)

  return (
    <button onClick={handleClick} style={{ borderRadius: "50%" }}>
      <StyledPlayShowReel
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        className={classNames(theme)}
      >
        <div className="wrap">
          <Video src={video.file.url} autoPlay />

          <Playbtn className="playBtn-hover" />
        </div>

        <motion.div
          className="text"
          animate={{ rotate: 360 }}
          transition={{ ease: "linear", duration: 20, repeat: Infinity }}
        >
          <CirclePlayText />
        </motion.div>
      </StyledPlayShowReel>
    </button>
  )
}

const StyledPlayShowReel = styled(motion.div)`
  border-radius: 50%;
  height: 140px;
  width: 140px;

  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  &.lab {
    background: black;
    box-shadow: 0px 0px 26px rgba(250, 250, 250, 0.2);

    svg {
      path {
        fill: white;
      }
    }
  }

  &:hover {
    .wrap {
      .playBtn-hover {
        opacity: 1;
      }
    }
  }

  .wrap {
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 75px;
    max-height: 75px;

    .playBtn-hover {
      transition: 0.2s opacity ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 2;
      border-radius: 50%;
      opacity: 0;
      text-align: center;
    }
  }

  .text {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 12px;
    svg {
      width: 100%;
      height: 100%;
    }

    text {
      font-family: "PP Mori";
      font-size: 1rem;
    }
  }
`

export default PlayShowReel
