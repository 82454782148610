import React, { useEffect } from "react"
import { useRef } from "react"
import { useRecoilValue } from "recoil"
import { waveThemeState } from "src/atoms"

import { Cursor1 } from "src/components/webgl/cursor"
import styled from "styled-components"

const Wave = () => {
  const waveRef = useRef(null)

  const theme = useRecoilValue(waveThemeState)

  useEffect(() => {
    waveRef.current = new Cursor1(1)

    return () => {
      waveRef.current.cleanup()
    }
  }, [])

  useEffect(() => {
    const { color1, color2, cursor } = theme
    if (waveRef.current) {
      waveRef.current.updateColors(color1, color2, cursor)
    }
  }, [theme])

  return <Container id="cursor-1" />
}

const Container = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100vw;

  /* overflow: hidden; */
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`

export default React.memo(Wave)
