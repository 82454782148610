import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"
import { useRecoilValue } from "recoil"
import { homeThemeState, waveThemeState } from "src/atoms"
import styled from "styled-components"

import Wave from "../webgl/Wave"

const PrismButton = () => {
  const theme = useRecoilValue(homeThemeState)
  const { cta, href } = useRecoilValue(waveThemeState)

  return (
    <StyledPrismButton className={classNames(theme)}>
      <div className="wave-container">
        <Wave />
      </div>
      <div className="mask " />
      <Link to={href} className="text">
        {cta}
      </Link>
    </StyledPrismButton>
  )
}

const StyledPrismButton = styled.div`
  position: relative;
  border-radius: 56px;
  /* box-shadow: var(--prism-button-shadow); */
  background-color: var(--prism-button-background);

  /* opacity: 0.9; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
    border-radius: 56px;
    z-index: 1;
  }

  .wave-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .mask {
    position: absolute;
    z-index: 2;

    top: 0;
    left: 1;
    width: 99%;
    height: 100%;
    background: conic-gradient(
      from 76.56deg at 50% 50%,
      #ff9900 -4.54deg,
      #ffe600 33deg,
      #0066ff 170.63deg,
      #00ffb2 210deg,
      #ff9900 355.06deg,
      #ff9900 355.46deg,
      #ffe600 393deg
    );

    /* background: red; */

    background-position: center;
    border-radius: 56px;
    mix-blend-mode: var(--prism-button-mode);
    cursor: pointer;

    animation: 5s linear infinite prism-button-rotate;
  }

  .text {
    display: inline-block;
    position: relative;
    z-index: 3;
    font-weight: 600;
    font-family: "PP Mori";
    font-size: 1.25rem;
    color: var(--prism-button-color);
    text-align: center;
    padding: 16px 32px;

    border-radius: 56px;

    &::before {
      content: "";
      z-index: 3;
      pointer-events: none;
      user-select: none;
      position: absolute;
      inset: 0px;
      border-radius: inherit;
      padding: 1px;

      background: conic-gradient(
        from 76.56deg at 50% 50%,
        #ff9900 -4.54deg,
        #ffe600 33deg,
        #0066ff 170.63deg,
        #00ffb2 210deg,
        #ff9900 355.06deg,
        #ff9900 355.46deg,
        #ffe600 393deg
      );

      mask: linear-gradient(black, black) content-box content-box,
        linear-gradient(black, black);
      mask-composite: xor;

      @media all and (min--moz-device-pixel-ratio: 0) {
        mask-composite: exclude;
      }

      animation: 5s linear infinite prism-button-rotate;
    }
  }

  &.lab {
    --prism-button-background: black;
    --prism-button-mode: multiply;
    --prism-button-color: white;
    --prism-button-shadow: 0px 0px 26px rgba(250, 250, 250, 0.2);
  }
`

export default PrismButton
