// import { isTouchDevices } from "./isTouchDevice"
import { Cursors } from "./cursors"
const isTouchDevices = false

export class Cursor1 extends Cursors {
  constructor(index) {
    super(index)
    this.speed = !isTouchDevices ? 0.5 : 1

    this.init()
    this.loop()
  }

  setParamsCursor() {
    this.fillCursor = this.cursorColor
    this.radiusCursor = 15
    this.maxSqueeze = 0.6
    this.accelerator = 1000
  }

  setParamsParticles() {
    this.strokeGradient = {
      idStrokeGradient: "gradient",
      color1: this.color1,
      color2: this.color2,
    }
    this.strokeWidthParticles = 1.5
    // this.strokeOpacityParticles = 0.2
    this.strokeOpacityParticles = 0.5
    // this.strokeOpacityParticles = 0.3
    // this.strokeOpacityParticles = 1

    // this.radiusDiff = 7
    // this.radiusDiff = 12
    // this.radiusDiff = 15
    this.radiusDiff = 20

    // this.radiusStart = this.radiusCursor * 3
    this.radiusStart = this.radiusCursor * 2.5

    this.nbrParticles = Math.round(
      (this.diagonalWindow() + this.radiusDiff - this.radiusStart) /
        this.radiusDiff
    )
    this.transitionParticles = {
      duration: 18,
      delay: !isTouchDevices ? 4 : 14,
      easing: "linear",
    }
  }
}
